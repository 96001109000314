function inicioCtrl(ResultadosBusqueda, $scope, $log){
    var vm = this;
    var buscador = $scope.$parent.buscador;
    vm.ficha = ResultadosBusqueda.ficha;
    buscador.mostrarMegabuscador = true;
    buscador.mostrarFiltros = true;
    buscador.mostrarSlider = true;

    $scope.link = 'https://youtu.be/2waM6CZjey0';

}