function contactoCtrl($scope, uiGmapGoogleMapApi, $log){
    var vm = this;
    var buscador = $scope.$parent.buscador;
    buscador.mostrarMegabuscador = false;
    buscador.mostrarFiltros = false;
    buscador.mostrarSlider = false;

    uiGmapGoogleMapApi.then(function(maps) {
        $scope.map = { center: { latitude: 37.421168, longitude: -2.672572 }, zoom: 10 };
    });
}