function fichaCtrl($scope, $cookies, Especies,  $stateParams, $log){
    var vm = this;
    var buscador = $scope.$parent.buscador;
    var aplicacion = $scope.$parent.aplicacion;
    vm.inicializar = function(){
        buscador.mostrarMegabuscador = true;
        buscador.mostrarFiltros = false;
        buscador.mostrarSlider = false;
        vm.ficha = {};
        Especies.get({id: $stateParams.id}, function(data){
            vm.ficha = data.especie;
        });
        vm.relacionadas = buscador.especies;
    }

    vm.inicializar();


    vm.favorita = function(especie){
        if(vm.esFavorita(especie)){
            $cookies.remove(especie.referencia);
        }else{
            $cookies.put(especie.referencia, especie);
        }
    }

    vm.esFavorita = function (especie) {
        var resultado = $cookies.get(especie.referencia);
        if(resultado != undefined){
            return true;
        }
        return false;
    }

}

