function appCtrl($scope, $location, $cookies, $log){
    var vm = this;
   // var buscador = $scope.$parent.buscador;
  //  $log.log(vm);


    vm.getClass = function (path) {
        if ($location.path().substr(0, path.length) === path) {
            return 'current-menu-item';
        } else {
            return 'menu-item-has-children';
        }
    }

    var calcularFavoritos = function(){
        var cantidad = 0;
        angular.forEach($cookies.getAll(), function(value, key) {
            if(key.substring(0, 2) == "VZ") {
                cantidad++;
            }
        });
        return cantidad;
    }

    $scope.$watch(function() { return $cookies.getAll(); }, function(newValue, oldValue) {
        if (newValue === oldValue) {
            return;
        }
        vm.numFavoritos = calcularFavoritos();
    });

    vm.numFavoritos = calcularFavoritos();

}