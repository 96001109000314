function catalogoCtrl($scope, $cookies, ResultadosBusqueda, $log){
    var vm = this;
    var buscador = $scope.$parent.buscador;
    vm.vista = "lista";
    buscador.mostrarMegabuscador = true;
    buscador.mostrarFiltros = false;
    buscador.mostrarSlider = false;

    vm.favorita = function(especie){
        if(vm.esFavorita(especie)){
            $cookies.remove(especie.referencia);
        }else{
            ResultadosBusqueda.especiesFavoritas[especie.referencia] = especie;
            $cookies.put(especie.referencia, especie);
        }
        vm.numFavoritos();
    }

    vm.esFavorita = function (especie) {
        var resultado = $cookies.get(especie.referencia);
        if(resultado != undefined){
            return true;
        }
        return false;
    }

    vm.numFavoritos = function(){
        var cantidad = 0;
        angular.forEach($cookies.getAll(), function(value, key) {
            if(key.substring(0, 2) == "VZ") {
                cantidad++;
            }
        });
        $log.log(cantidad);
        return cantidad;
    }



}