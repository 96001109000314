function buscadorCtrl(TodoEspecies, Familias, Generos, Biotipos, Colores, Zonas, Filtros, Epocas, $filter, $log, $scope){
    var vm = this;
    vm.author = "Miguel Hernández";
    vm.reset = function(){
        vm.genero = {};
        vm.biotipo = {};
        vm.zona = {};
        vm.familia = {};
        vm.fColor = { colores: [] };
        vm.fEpoca = {};
        vm.filtro = {
            autoctona: false,
            aloctona: false,
            perennifolia: false,
            caducifolia: false,
            gramineas_y_juncos: false,
            coniferas: false,
            palmeras: false,
            crasas: false,
            trepadoras: false,
            aromaticas: false,
            meliferas: false,
            zonas_litorales: false,
            zonas_de_sombra: false,
            zonas_humedas: false,
            tapizantes: false,
            setos: false,
            borduras: false
        };
    }
    vm.megabuscador = "";
    vm.reset();
    vm.mostrarSlider = true;
    vm.mostrarMegabuscador = true;
    vm.mostrarFiltros = true;

    vm.selectGenero = function($item) {
        vm.familia = {};
    };
    vm.selectFamilia = function($item) {
        vm.genero = {};
    };
    vm.clearGenero = function($event) {
        $event.stopPropagation();
        vm.genero = {};
    };
    vm.clearFamilia = function($item, $model) {
        vm.familia = {};
    };
    vm.grupo3 = function($item){
        if($item !== 'gramineas_y_juncos')
            vm.filtro.gramineas_y_juncos = false;
        if($item !== 'coniferas')
            vm.filtro.coniferas = false;
        if($item !== 'palmeras')
            vm.filtro.palmeras = false;
        if($item !== 'crasas')
            vm.filtro.crasas = false;
        if($item !== 'trepadoras')
            vm.filtro.trepadoras = false;
    }

    $scope.$watch("buscador.megabuscador", function(newValue, oldValue) {
        if (newValue === oldValue) {
            return;
        }
        if(newValue !== ''){
            vm.reset();
            vm.mostrarFiltros = true;
        }
    });
    vm.filtrado = function(item) {
        if(vm.megabuscador == '') {
            if (vm.genero.hasOwnProperty('selected') && vm.genero.selected != undefined) {
                if ((item.genero_id !== vm.genero.selected.id) == true) return false;
            }
            if (vm.familia.hasOwnProperty('selected') && vm.familia.selected != undefined) {
                if ((item.familia_id !== vm.familia.selected.id) == true) return false;
            }
            if (vm.biotipo.hasOwnProperty('selected') && vm.biotipo.selected != undefined) {
                if ((item.biotipo_id !== vm.biotipo.selected.id) == true) return false;
            }
            if (vm.zona.hasOwnProperty('selected') && vm.zona.selected != undefined) {
                if ((item.zona_id !== vm.zona.selected.id) == true) return false;
            }
            var activos = {
                autoctona: null,
                aloctona: null,
                perennifolia: null,
                caducifolia: null,
                gramineas_y_juncos: null,
                coniferas: null,
                palmeras: null,
                crasas: null,
                trepadoras: null,
                aromaticas: null,
                meliferas: null,
                zonas_litorales: null,
                zonas_de_sombra: null,
                zonas_humedas: null,
                tapizantes: null,
                setos: null,
                borduras: null
            };
            var coloresEspecie = [];
            var coloresSeleccionados = [];
            if (vm.fColor.hasOwnProperty('colores')) {
                for (var j = 0; j < vm.fColor.colores.length; j++) {
                    var c = vm.fColor.colores[j];
                    coloresSeleccionados.push(c);
                }
            }
            if (coloresSeleccionados.length > 0) {
                for (var i = 0; i < item.colores.length; i++) {
                    var c = item.colores[i];
                    coloresEspecie.push(c);
                }
                for (var k = 0; k < coloresSeleccionados.length; k++) {
                    var incluido = false;
                    for (var l = 0; l < coloresEspecie.length; l++) {
                        if (coloresSeleccionados[k].nombre === coloresEspecie[l].nombre) {
                            incluido = true;
                            break;
                        }
                    }
                    if (!incluido) {
                        return false;
                    }
                }
            }
            var epocasEspecie = [];
            var epocasSeleccionados = [];
            if (vm.fEpoca.hasOwnProperty('epocas')) {
                for (var j = 0; j < vm.fEpoca.epocas.length; j++) {
                    var c = vm.fEpoca.epocas[j];
                    epocasSeleccionados.push(c);
                }
            }
            if (epocasSeleccionados.length > 0) {
                for (var i = 0; i < item.epocas.length; i++) {
                    var c = item.epocas[i];
                    epocasEspecie.push(c);
                }
                for (var k = 0; k < epocasSeleccionados.length; k++) {
                    var incluido = false;
                    for (var l = 0; l < epocasEspecie.length; l++) {
                        if (epocasSeleccionados[k].mes === epocasEspecie[l].mes) {
                            incluido = true;
                            break;
                        }
                    }
                    if (!incluido) {
                        return false;
                    }
                }
            }
            for (var f in item.filtros) { //recorremos todos los filtros del item
                var filt = item.filtros[f];
                if (filt.nombre === "Autóctona") activos.autoctona = true;
                if (filt.nombre === "Alóctona") activos.aloctona = true;
                if (filt.nombre === "Perennifolia") activos.perennifolia = true;
                if (filt.nombre === "Caducifolia") activos.caducifolia = true;
                if (filt.nombre === "Gramíneas y juncos") activos.gramineas_y_juncos = true;
                if (filt.nombre === "Coníferas") activos.coniferas = true;
                if (filt.nombre === "Palmeras") activos.palmeras = true;
                if (filt.nombre === "Crasas") activos.crasas = true;
                if (filt.nombre === "Trepadoras") activos.trepadoras = true;
                if (filt.nombre === "Aromáticas") activos.aromaticas = true;
                if (filt.nombre === "Melíferas") activos.meliferas = true;
                if (filt.nombre === "Zonas litorales") activos.zonas_litorales = true;
                if (filt.nombre === "Zonas de sombra") activos.zonas_de_sombra = true;
                if (filt.nombre === "Zonas húmedas") activos.zonas_humedas = true;
                if (filt.nombre === "Tapizantes") activos.tapizantes = true;
                if (filt.nombre === "Setos") activos.setos = true;
                if (filt.nombre === "Borduras") activos.borduras = true;
            }
            if (vm.filtro.autoctona && activos.autoctona !== true) return false;
            if (vm.filtro.aloctona && activos.aloctona !== true) return false;
            if (vm.filtro.perennifolia && activos.perennifolia !== true) return false;
            if (vm.filtro.caducifolia && activos.caducifolia !== true) return false;
            if (vm.filtro.gramineas_y_juncos && activos.gramineas_y_juncos !== true) return false;
            if (vm.filtro.coniferas && activos.coniferas !== true) return false;
            if (vm.filtro.palmeras && activos.palmeras !== true) return false;
            if (vm.filtro.crasas && activos.crasas !== true) return false;
            if (vm.filtro.trepadoras && activos.trepadoras !== true) return false;
            if (vm.filtro.aromaticas && activos.aromaticas !== true) return false;
            if (vm.filtro.meliferas && activos.meliferas !== true) return false;
            if (vm.filtro.zonas_litorales && activos.zonas_litorales !== true) return false;
            if (vm.filtro.zonas_de_sombra && activos.zonas_de_sombra !== true) return false;
            if (vm.filtro.zonas_humedas && activos.zonas_humedas !== true) return false;
            if (vm.filtro.tapizantes && activos.tapizantes !== true) return false;
            if (vm.filtro.setos && activos.setos !== true) return false;
            if (vm.filtro.borduras && activos.borduras !== true) return false;
            return true;
        }else{
            return true;
        }
    };

    TodoEspecies.get(function(data) {
        vm.especies = data.especies;
    });
    Generos.get(function(data) {
        vm.generos = data.generos;
    });
    Familias.get(function(data) {
        vm.familias = data.familias;
    });
    Biotipos.get(function(data){
        vm.biotipos = data.biotipos;
    });
    Zonas.get(function(data) {
        vm.zonas = data.zonas;
    });
    Filtros.get(function(data) {
        vm.filtros = data.filtros;
    });
    Epocas.get(function(data) {
        vm.epocas = data.epocas;
    });
    Colores.get(function(data) {
        vm.colores = data.colores;
    });
}
